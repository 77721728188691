// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-default-js": () => import("../src/templates/page-default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-email-catcher-lp-js": () => import("../src/templates/email-catcher-lp.js" /* webpackChunkName: "component---src-templates-email-catcher-lp-js" */),
  "component---src-templates-are-you-scrum-js": () => import("../src/templates/are-you-scrum.js" /* webpackChunkName: "component---src-templates-are-you-scrum-js" */),
  "component---src-templates-blog-detail-js": () => import("../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-case-detail-js": () => import("../src/templates/case-detail.js" /* webpackChunkName: "component---src-templates-case-detail-js" */),
  "component---src-templates-call-us-landing-js": () => import("../src/templates/call-us-landing.js" /* webpackChunkName: "component---src-templates-call-us-landing-js" */),
  "component---src-templates-design-detail-js": () => import("../src/templates/design-detail.js" /* webpackChunkName: "component---src-templates-design-detail-js" */),
  "component---src-templates-free-planning-poker-cards-js": () => import("../src/templates/free-planning-poker-cards.js" /* webpackChunkName: "component---src-templates-free-planning-poker-cards-js" */),
  "component---src-templates-scrum-or-scrumbut-js": () => import("../src/templates/scrum-or-scrumbut.js" /* webpackChunkName: "component---src-templates-scrum-or-scrumbut-js" */),
  "component---src-templates-home-page-slide-js": () => import("../src/templates/home-page-slide.js" /* webpackChunkName: "component---src-templates-home-page-slide-js" */),
  "component---src-templates-thank-you-page-js": () => import("../src/templates/thank-you-page.js" /* webpackChunkName: "component---src-templates-thank-you-page-js" */),
  "component---src-templates-tags-js": () => import("../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tags-index-js": () => import("../src/templates/tags/index.js" /* webpackChunkName: "component---src-templates-tags-index-js" */),
  "component---src-templates-categories-js": () => import("../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-categories-index-js": () => import("../src/templates/categories/index.js" /* webpackChunkName: "component---src-templates-categories-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cases-index-js": () => import("../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-designs-index-js": () => import("../src/pages/designs/index.js" /* webpackChunkName: "component---src-pages-designs-index-js" */)
}

