exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    const currentPosition = getSavedScrollPosition(location)
   

    if(window.document.location.pathname === `/cases` || window.document.location.pathname === `/designs`){
        if(sessionStorage.getItem('case_list_y')){
            window.scrollTo(0, sessionStorage.getItem('case_list_y'))
        }else{
            window.scrollTo(0,0)
        }
      
        return false
    }else if(window.document.location.pathname === `/blog`){
        if(sessionStorage.getItem('blog_list_y')){
            window.scrollTo(0, sessionStorage.getItem('blog_list_y'))
        }else{
            window.scrollTo(0,0)
        }
      
        return false
    }

  
   
  }
  